import { Typography } from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Stack } from '@superdispatch/ui';
import { Button } from '@superdispatch/ui-lab';
import { useMemo } from 'react';
import { showIntercomMessenger } from 'shared/helpers/Intercom';
import { WebViewAnchorButton } from 'shared/routing/WebViewLinks';
import { useCarrierPerformanceIndicators } from '../data/CarrierPerformanceAPI';
import {
  calculatePhotosOnSiteMeanRate,
  photosTakenThresholds,
} from './PerformanceHelpers';

function PhotosTakenOnSiteTopPerformer() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">You are a top performer!</Typography>
      <Typography color="textSecondary">
        Your drivers consistently take photos on-site at both pick-up and
        delivery for all vehicles in every load helping you resolve claims and
        build trust with shippers.
      </Typography>
    </Stack>
  );
}

function PhotosTakenOnSiteGoodMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You&apos;re on the right track, but there&apos;s still room to improve.
      </Typography>
      <Typography color="textSecondary">
        Require drivers to take photos on-site at both pick-up and delivery for
        all vehicles in every load to help resolve claims and build trust with
        shippers.
      </Typography>
    </Stack>
  );
}

function PhotosTakenOnSiteCanImproveMetrics() {
  return (
    <Stack space="xsmall">
      <Typography variant="h4">
        You have room for improvement on this metric.
      </Typography>
      <Typography color="textSecondary">
        Require drivers to take photos on-site at both pick-up and delivery for
        all vehicles in every load to help resolve claims and build trust with
        shippers.
      </Typography>
    </Stack>
  );
}

export default function PhotosTakenOnSiteDrawerContent() {
  const { data } = useCarrierPerformanceIndicators();

  const meanRate = useMemo(
    () => (data ? calculatePhotosOnSiteMeanRate(data) : null),
    [data],
  );

  const variants = useMemo(
    () => (meanRate != null ? photosTakenThresholds(meanRate) : null),
    [meanRate],
  );

  if (meanRate == null || variants == null) return null;

  return (
    <Stack space="small">
      {variants.top ? (
        <PhotosTakenOnSiteTopPerformer />
      ) : variants.good ? (
        <PhotosTakenOnSiteGoodMetrics />
      ) : (
        <PhotosTakenOnSiteCanImproveMetrics />
      )}
      <Stack space="xsmall">
        <Typography variant="h4">What is counted?</Typography>
        <Typography color="textSecondary">
          The percentage of times photos are taken at Pickup and Delivery
          locations for Advanced Inspections.
        </Typography>
      </Stack>
      <Stack space="small">
        <Typography variant="h4">Can we help?</Typography>
        <WebViewAnchorButton
          variant="text"
          endIcon={<ChevronRight />}
          target="_blank"
          href="https://support.superdispatch.com/en/articles/7913694-carrier-tms-carrier-performance-metrics"
        >
          Read our Article
        </WebViewAnchorButton>
        {variants.canImprove && (
          <Button
            variant="text"
            endIcon={<ChevronRight />}
            onClick={() => {
              showIntercomMessenger();
            }}
          >
            Talk to Us
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
